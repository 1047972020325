export function bankNames() {
  return [
    "BNP PARIBAS MALAYSIA BERHAD",
    "BANK OF AMERICA MALAYSIA BERHAD",
    "MUFG BANK (MALAYSIA) BERHAD",
    "JPMORGAN CHASE BANK, N.A. ? LABUAN BRANCH",
    "J.P. MORGAN CHASE BANK BERHAD",
    "CIMB BANK BERHAD, LABUAN OFFSHORE BRANCH",
    "CIMB BANK BERHAD",
    "CITIBANK BERHAD",
    "DEUTSCHE BANK (MALAYSIA) BERHAD",
    "HSBC BANK MALAYSIA BERHAD",
    "HONG LEONG BANK BERHAD",
    "HSBC AMANAH MALAYSIA BERHAD",
    "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED",
    "HSBC (MALAYSIA) TRUSTEE BERHAD",
    "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (MALAYSIA) BERHAD.",
    "MAYBANK INTERNATIONAL LABUAN BRANCH",
    "MAYBANK INTERNATIONAL (L) LTD",
    "MALAYAN BANKING BERHAD (MAYBANK)",
    "ALLIANCE BANK MALAYSIA BERHAD",
    "MIZUHO BANK (MALAYSIA) BERHAD",
    "THE BANK OF NOVA SCOTIA BERHAD",
    "PUBLIC BANK BERHAD",
    "AFFIN BANK BERHAD",
    "RHB BANK BERHAD",
    "STANDARD CHARTERED BANK MALAYSIA BERHAD",
    "SUMITOMO MITSUI BANKING CORPORATION MALAYSIA BERHAD",
    "UNITED OVERSEAS BANK (MALAYSIA) BHD",
    "FIRST ABU DHABI BANK PJSC",
  ];
}
