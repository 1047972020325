<template>
  <v-container>
    <v-alert
      v-if="alertMsg.status"
      :timeout="alertMsg.timeout"
      :color="alertMsg.color"
      :type="alertMsg.type"
      dismissible
    >
      {{ alertMsg.text }}
    </v-alert>
    <v-form v-model="valid" ref="busEntity">
      <v-row>
        <v-col cols="12" md="6">
          <label
            >{{ $t("entity_type_business")
            }}<span style="color: #f00">*</span></label
          >
          <v-autocomplete
            v-model="addProfilePayload.entity_type"
            :items="entityType_options"
            :rules="EntityRules.entity_typeRules"
            item-text="name"
            item-value="id"
            :placeholder="$t('entity_type_placeholder')"
            outlined
            dense
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <label v-if="addProfilePayload.entity_type == '9'"
            >{{ $t("name_of_the_charities")
            }}<span style="color: #f00">*</span></label
          >
          <label v-else
            >{{ $t("name_of_the_company_business")
            }}<span style="color: #f00">*</span></label
          >
          <v-text-field
            dense
            :placeholder="
              addProfilePayload.entity_type == '9'
                ? $t('name_of_the_charities')
                : $t('name_of_the_company_placeholder')
            "
            outlined
            required
            v-model="addProfilePayload.business_name"
            :rules="EntityRules.CompanyNameRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("registration_no_business")
            }}<span style="color: #f00">*</span></label
          >
          <v-text-field
            :placeholder="$t('registration_no_placeholder')"
            dense
            outlined
            required
            v-model="addProfilePayload.registration_number"
            :rules="EntityRules.RegistrationNoRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <label
            >{{ $t("date_of_incorporation_business")
            }}<span style="color: #f00">*</span></label
          >
          <v-text-field
            :placeholder="$t('date_of_incorporation_placeholder')"
            dense
            type="date"
            outlined
            :max="new Date().toJSON().slice(0, 10)"
            required
            v-model="addProfilePayload.dob"
            :rules="EntityRules.DoiRules"
            @change="ValidateDateofIncop(addProfilePayload.dob)"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <label
            >{{ $t("source_of_funds_business")
            }}<span style="color: #f00">*</span></label
          >
          <v-autocomplete
            v-model="addProfilePayload.source_of_fund_code"
            :items="source_options"
            item-text="name"
            item-value="id"
            :rules="EntityRules.SOFrules"
            :placeholder="$t('source_of_funds_placeholder')"
            outlined
            dense
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("nature_of_business_business")
            }}<span style="color: #f00">*</span></label
          >
          <v-autocomplete
            v-model="addProfilePayload.nature_of_business"
            :rules="EntityRules.natureOfBusinessErrors"
            :items="natureofbusList"
            item-text="name"
            item-value="id"
            :placeholder="$t('nature_of_business_placeholder')"
            dense
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <label>{{ $t("website_business") }}</label>
          <v-text-field
            v-model="addProfilePayload.website"
            :placeholder="$t('website_placeholder')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("contactNumber") }}<span style="color: #f00">*</span></label
          >
          <vue-phone-number-input
            default-country-code="MY"
            @update="onChangeNumber"
            :error="!isValidNumber"
            v-model="addProfilePayload.phone_number"
            :rules="EntityRules.contactNoErrors"
            class=""
          />
          <!-- <span style="color: #ff5252; font-size: 12px">{{
            EntityRules.contactNoErrors[0]
          }}</span> -->
        </v-col>
        <v-col cols="12" md="6">
          <label>{{ $t("average_annual_turnover_business") }}</label>
          <v-text-field
            :placeholder="$t('average_annual_turnover_placeholder')"
            dense
            outlined
            required
            type="number"
            hide-spin-buttons
            v-model="addProfilePayload.salary_range"
          ></v-text-field>
          <!-- :rules="EntityRules.turnoverErrors" -- validation for average_annual_turnover_business -->
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("businessEmail") }}<span style="color: #f00">*</span></label
          >
          <v-text-field
            v-model="addProfilePayload.business_email"
            :rules="EntityRules.emailValidations"
            :placeholder="$t('email_business')"
            dense
            outlined
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <label
            >{{ $t("corporate_address_business")
            }}<span style="color: #f00">*</span></label
          >
          <v-text-field
            :placeholder="$t('corporate_address_placeholder')"
            outlined
            required
            dense
            v-model="addressInfo.mailing_address"
            :rules="EntityRules.corporateAddErrors"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("country_business")
            }}<span style="color: #f00">*</span></label
          >
          <v-autocomplete
            :placeholder="$t('select_country_placeholder')"
            :items="country_options"
            v-model="addressInfo.mailing_country"
            item-text="name"
            item-value="id"
            :rules="EntityRules.countryErrors"
            @input="onChangeCounty($event)"
            outlined
            required
            dense
            disabled
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("state_business") }}<span style="color: #f00">*</span></label
          >
          <v-autocomplete
            v-model="addressInfo.mailing_state"
            :items="state_options"
            :placeholder="$t('select_state_placeholder')"
            item-text="name"
            item-value="id"
            outlined
            required
            :rules="EntityRules.mailingStateErrors"
            @input="onChangeState($event)"
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <label>{{ $t("city") }}<span style="color: #f00">*</span></label>
          <v-autocomplete
            v-if="haveCities"
            v-model="addressInfo.mailing_city"
            :placeholder="$t('select_city_placeholder')"
            :items="city_options"
            item-text="txt"
            item-value="val"
            :rules="EntityRules.mailingCityErrors"
            outlined
            required
            dense
          >
          </v-autocomplete>
          <v-text-field
            v-else
            v-model="cityValue"
            :placeholder="$t('enter_city')"
            :rules="EntityRules.citytextErrors"
            outlined
            required
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("postalCode") }}<span style="color: #f00">*</span></label
          >
          <v-autocomplete
            v-model="addressInfo.mailing_postal_code"
            :items="postalCodeslist"
            :rules="EntityRules.postalCodeErrors"
            :placeholder="$t('postal_code_placeholder')"
            outlined
            dense
          ></v-autocomplete>
        </v-col>

        <!-- <label>{{ $t("mailingAddress") }}</label> -->
        <v-col cols="12" md="6" class="mt-4">
          <v-checkbox
            v-model="
              addProfilePayload.is_mailing_address_same_as_business_address
            "
            :label="$t('sameAsBusinessAddress')"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row
        v-if="!addProfilePayload.is_mailing_address_same_as_business_address"
      >
        <v-col cols="12" md="6">
          <label>{{ $t("street_no") }}</label>
          <v-text-field
            :placeholder="$t('street_no_placeholder')"
            outlined
            dense
            v-model="businessAddress.street_number"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <label
            >{{ $t("business_address")
            }}<span style="color: #f00">*</span></label
          >
          <v-text-field
            :placeholder="$t('Enter Business Address')"
            outlined
            required
            dense
            v-model="businessAddress.address"
            :rules="
              !addProfilePayload.is_mailing_address_same_as_business_address
                ? EntityRules.corporateAddErrors
                : []
            "
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("country_business")
            }}<span style="color: #f00">*</span></label
          >
          <v-autocomplete
            :placeholder="$t('select_country_placeholder')"
            :items="country_options"
            v-model="businessAddress.country_code"
            item-text="name"
            item-value="id"
            :rules="
              !addProfilePayload.is_mailing_address_same_as_business_address
                ? EntityRules.countryErrors
                : []
            "
            @input="onChangeCounty($event)"
            outlined
            required
            dense
            disabled
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("state_business") }}<span style="color: #f00">*</span></label
          >
          <v-autocomplete
            v-model="businessAddress.state_code"
            :items="state_options"
            :placeholder="$t('select_state_placeholder')"
            item-text="name"
            item-value="id"
            outlined
            required
            :rules="
              !addProfilePayload.is_mailing_address_same_as_business_address
                ? EntityRules.stateErrors
                : []
            "
            @input="onChangemailingState($event)"
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <label>{{ $t("city") }}<span style="color: #f00">*</span></label>
          <v-autocomplete
            v-if="haveCities"
            v-model="businessAddress.city_code"
            :placeholder="$t('select_city_placeholder')"
            :items="maling_city_options"
            item-text="txt"
            item-value="val"
            :rules="
              !addProfilePayload.is_mailing_address_same_as_business_address
                ? EntityRules.cityErrors
                : []
            "
            outlined
            required
            dense
          >
          </v-autocomplete>
          <v-text-field
            v-else
            v-model="cityValue"
            :placeholder="$t('enter_city')"
            :rules="
              !addProfilePayload.is_mailing_address_same_as_business_address
                ? EntityRules.citytextErrors
                : []
            "
            outlined
            required
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <label
            >{{ $t("postalCode") }}<span style="color: #f00">*</span></label
          >
          <v-autocomplete
            v-model="businessAddress.zip_code"
            :items="postalCodeslist"
            :rules="
              !addProfilePayload.is_mailing_address_same_as_business_address
                ? EntityRules.postalCodeErrors
                : []
            "
            :placeholder="$t('postal_code_placeholder')"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-btn
            v-if="is_update"
            color="primary"
            class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
            @click="updateEntityinfo"
            >{{ $t("Update") }}
          </v-btn>
          <v-btn
            v-else
            color="primary"
            class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
            @click="onClickNext"
            >{{ $t("Save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <loading
      :active="loader"
      :is-full-page="true"
      :opacity="0.9"
      color="#ff6b00"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import axios from "axios";
import Loading from "vue-loading-overlay";
import { entityTypes } from "../../utils/BusDocuments";
// import { alpha } from "../../../utils/validate";
import { i18n as $ } from "../../locale/index";
export default {
  data: () => ({
    isValidNumber: false,
    valid: true,
    is_update: false,
    loader: false,
    entityType_options: [],
    entityTypes: entityTypes(),
    source_options: [],
    doistate: false,
    source_of_funds: "",
    addProfilePayload: {
      entity_type: "",
      business_name: "",
      registration_number: "",
      nature_of_business: "",
      website: "",
      business_email: "",
      street_number: "",
      mailing_address: "",
      mailing_country: "",
      mailing_state: "",
      mailing_city: "",
      mailing_postal_code: "",
      is_mailing_address_same_as_business_address: false,
      dob: null,
      source_of_fund_code: "",
      phone_number: "",
      salary_range: "",
      address: "",
      country_code: "",
      state_code: "",
      city_code: "",
      zip_code: "",
    },

    alertMsg: {
      status: false,
      text: "",
      timeout: 2000,
      color: "blue-grey",
      type: "info",
    },
    country_options: [],
    state_options: [],
    city_options: [],
    maling_city_options: [],
    isSubmitted: false,
    addressInfo: {
      mailing_address: "",
      mailing_country: {
        id: 132,
        iso2: "MY",
        iso3: "MYS",
        name: "Malaysia",
      },
      mailing_state: "",
      mailing_city: "",
      mailing_postal_code: "",
    },
    businessAddress: {
      street_number: "",
      address: "",
      country_code: {},
      state_code: "",
      city_code: "",
      zip_code: "",
    },
    EntityRules: {
      entity_typeRules: [(v) => !!v || $.t("entity_typeRules")],
      CompanyNameRules: [(v) => !!v || $.t("CompanyNameRules")],
      RegistrationNoRules: [(v) => !!v || $.t("RegistrationNoRules")],
      DoiRules: [(v) => !!v || $.t("DoiRules")],
      SOFrules: [(v) => !!v || $.t("SOFrules")],
      natureOfBusinessErrors: [(v) => !!v || $.t("natureOfBusinessErrors")],
      contactNoErrors: [(v) => !!v || $.t("contactNoErrors")],
      emailValidations: [
        (v) => !!v || $.t("email_errors"),
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          $.t("invalid_email"),
      ],
      turnoverErrors: [(v) => !!v || $.t("turnoverErrors")],
      corporateAddErrors: [
        (v) => !!v || $.t("address_req"),
        (v) => v.length < 98 || $.t("address_minlength"),
      ],
      countryErrors: [(v) => !!v || $.t("country_field_required")],
      mailingStateErrors: [(v) => !!v || $.t("state_field_is_required")],
      mailingCityErrors: [(v) => !!v || $.t("city_placeholder_beneficary")],
      stateErrors: [(v) => !!v || $.t("state_field_is_required")],
      cityErrors: [(v) => !!v || $.t("city_placeholder_beneficary")],
      citytextErrors: [(v) => !!v || $.t("city_placeholder_beneficary")],
      postalCodeErrors: [
        (v) => !!v || $.t("postalcode_placeholder_beneficary"),
      ],
    },
    cityValue: "",
    haveCities: true,
    cityBusValue: "",
    haveBusCities: true,
    postalCodeslist: [],
    natureofbusList: [],
  }),
  name: "BasicDetails",

  components: {
    VuePhoneNumberInput,
    Loading,
  },

  watch: {
    "addProfilePayload.entity_type"(val) {
      let entity_type = this.entityTypes.entityTypes.find(
        (type) => type.value_code === val
      );
      let entity_name = entity_type.value;
      if (entity_name) {
        this.$emit("selctedEntitytype", entity_name);
      }
    },
    "addProfilePayload.dob"(val) {
      const incorpDate = "2017-01-31";
      console.log(incorpDate);
      console.log(
        "addProfilePayload.entity_type",
        this.addProfilePayload.entity_type
      );
      console.log("time", val);
      if (this.addProfilePayload.entity_type === "1" && val > incorpDate) {
        this.$emit("isHideform", "form_9");
      } else if (
        this.addProfilePayload.entity_type === "1" &&
        val < incorpDate
      ) {
        this.$emit("isHideform", "form_51");
      }
    },
  },
  computed: {
    // ...mapGetters("Transactional", ["realtion_list", "source_list"]),
    ...mapGetters("Authentication", [
      "country_list",
      "state_list",
      "city_list",
    ]),
  },
  mounted() {},
  created() {
    this.getentitydetails();
    this.getEntityType();
    this.addProfilePayload.entity_type = "1";
    this.getCountryList();
    this.getPostalCodes();
    this.addressInfo.mailing_country = "MYS";
    this.businessAddress.country_code = "MYS";
    this.getSourceFunds();
    this.getnatureOfBusiness();
    this.onChangeCounty("MYS");
  },
  methods: {
    ValidateDateofIncop(value) {
      console.log("value", value);
      let presentDay = new Date().toJSON().slice(0, 10);
      console.log("presentDay", presentDay);
      if (value > presentDay) {
        this.doistate = true;
        this.EntityRules.DoiRules.push($.t("invalid_doi"));
      } else {
        this.doistate = false;
        this.EntityRules.DoiRules = [
          (v) => !!v || $.t("postalcode_placeholder_beneficary"),
        ];
      }
    },
    onChangeNumber(e) {
      this.isValidNumber = e.isValid;
    },
    onClickBack() {
      this.$emit("onClickBack");
    },

    getentitydetails() {
      this.loader = true;
      const token = sessionStorage.getItem("access_token");
      axios
        .get(
          `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/get-entity-information`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status_code == 200) {
            this.is_update = true;
            this.combinedData = response.data.data[0];
            this.addProfilePayload = {
              entity_type: response.data.data[0].entity_type.value_code,
              business_name: response.data.data[0].business_name,
              registration_number: response.data.data[0].registration_number,
              business_email: response.data.data[0].business_email,
              nature_of_business:
                response.data.data[0].nature_of_business.value_code,
              is_mailing_address_same_as_business_address:
                response.data.data[0]
                  .is_mailing_address_same_as_business_address,
              website: response.data.data[0].website,
              dob: response.data.data[0].dob,
              source_of_fund_code:
                response.data.data[0].source_of_fund_code.value_code,
              salary_range: response.data.data[0].salary_range,
              phone_number: response.data.data[0].phone_number,
            };

            console.log("uu", this.addProfilePayload);
            (this.addressInfo = {
              mailing_address: response.data.data[0].mailing_address,
              mailing_country: response.data.data[0].mailing_country.iso_alpha3,
              mailing_state: response.data.data[0].mailing_state.state_code,
              mailing_city: response.data.data[0].mailing_city.id,
              mailing_postal_code: response.data.data[0].mailing_postal_code,
            }),
              console.log("add", this.addressInfo);
            (this.businessAddress = {
              street_number: response.data.data[0].street_number,
              address: response.data.data[0].address,
              country_code: response.data.data[0].country_code.iso_alpha3,
              state_code: response.data.data[0].state_code.state_code,
              city_code: response.data.data[0].city_code.id,
              zip_code: response.data.data[0].zip_code,
            }),
              (this.loader = false);
            console.log("buu", this.businessAddress);
            console.log("response", response);
          } else {
            this.loader = false;
            this.is_update = false;
          }
        })

        .catch((error) => {
          console.error(error);
          this.is_update = false;
          this.loader = false;
        });
    },

    async onClickNext() {
      if (this.$refs.busEntity.validate()) {
        const combinedData = {
          entity_type: this.addProfilePayload.entity_type,
          business_name: this.addProfilePayload.business_name,
          registration_number: this.addProfilePayload.registration_number,
          nature_of_business: this.addProfilePayload.nature_of_business,
          website: this.addProfilePayload.website,
          business_email: this.addProfilePayload.business_email,
          is_mailing_address_same_as_business_address:
            this.addProfilePayload.is_mailing_address_same_as_business_address,
          dob: this.addProfilePayload.dob,
          source_of_fund_code: this.addProfilePayload.source_of_fund_code,
          salary_range: this.addProfilePayload.salary_range,
          phone_number: this.addProfilePayload.phone_number,
          mailing_address: this.addressInfo.mailing_address,
          mailing_country: this.addressInfo.mailing_country,
          mailing_state: this.addressInfo.mailing_state,
          mailing_city: this.addressInfo.mailing_city,
          mailing_postal_code: this.addressInfo.mailing_postal_code,
          street_number: this.businessAddress.street_number,
          address: this.businessAddress.address,
          country_code: this.businessAddress.country_code,
          state_code: this.businessAddress.state_code,
          city_code: this.businessAddress.city_code,
          zip_code: this.businessAddress.zip_code,
        };
        this.loader = true;
        const token = sessionStorage.getItem("access_token");
        await axios
          .post(
            `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/entity-information`,
            combinedData,
            {
              headers: {
                ContentType: "application/json",
                Authorization: `Bearer ${token}`,
                accept: "application/json",
              },
            }
          )
          .then((responseData) => {
            console.log(`responseData: ${JSON.stringify(responseData)}`);
            if (responseData && responseData.data.status_code == 200) {
              this.loader = false;
              this.alertMsg.status = true;
              this.alertMsg.text = responseData.data.message;
              this.alertMsg.type = "success";
              this.alertMsg.color = "green";
            } else {
              this.loader = false;
              this.alertMsg.status = true;
              this.alertMsg.text = `Failed: ${responseData.data.message}`;
              this.alertMsg.type = "error";
              this.alertMsg.color = "warning";
            }
            console.log("respose data", responseData);
          })
          .catch((error) => {
            console.error(error);
            this.alertMsg.status = true;
            this.alertMsg.text =
              "Error: Something went wrong please try again later!";
            this.alertMsg.type = "error";
            this.alertMsg.color = "error";
          });
      }
    },
    async updateEntityinfo() {
      if (this.$refs.busEntity.validate()) {
        const combinedData = {
          entity_type: this.addProfilePayload.entity_type,
          business_name: this.addProfilePayload.business_name,
          registration_number: this.addProfilePayload.registration_number,
          nature_of_business: this.addProfilePayload.nature_of_business,
          website: this.addProfilePayload.website,
          business_email: this.addProfilePayload.business_email,
          is_mailing_address_same_as_business_address:
            this.addProfilePayload.is_mailing_address_same_as_business_address,
          dob: this.addProfilePayload.dob,
          source_of_fund_code: this.addProfilePayload.source_of_fund_code,
          salary_range: this.addProfilePayload.salary_range,
          phone_number: this.addProfilePayload.phone_number,
          mailing_address: this.addressInfo.mailing_address,
          mailing_country: this.addressInfo.mailing_country,
          mailing_state: this.addressInfo.mailing_state,
          mailing_city: this.addressInfo.mailing_city,
          mailing_postal_code: this.addressInfo.mailing_postal_code,
          street_number: this.businessAddress.street_number,
          address: this.businessAddress.address,
          country_code: this.businessAddress.country_code,
          state_code: this.businessAddress.state_code,
          city_code: this.businessAddress.city_code,
          zip_code: this.businessAddress.zip_code,
        };
        this.loader = true;
        const token = sessionStorage.getItem("access_token");
        await axios
          .put(
            `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/update-entity-information`,
            combinedData,
            {
              headers: {
                ContentType: "application/json",
                Authorization: `Bearer ${token}`,
                accept: "application/json",
              },
            }
          )
          .then((responseData) => {
            console.log(`responseData: ${JSON.stringify(responseData)}`);
            if (responseData && responseData.data.status_code == 200) {
              this.loader = false;
              this.alertMsg.status = true;
              this.alertMsg.text = responseData.data.message;
              this.alertMsg.type = "success";
              this.alertMsg.color = "green";
            } else {
              this.loader = false;
              this.alertMsg.status = true;
              this.alertMsg.text = `Failed: ${responseData.data.message}`;
              this.alertMsg.type = "error";
              this.alertMsg.color = "warning";
            }
            console.log("respose data", responseData);
          })
          .catch((error) => {
            this.loader = false;
            console.error(error);
            this.alertMsg.status = true;
            this.alertMsg.text =
              "Error: Something went wrong please try again later!";
            this.alertMsg.type = "error";
            this.alertMsg.color = "error";
          });
      }
    },

    async getSourceFunds() {
      const responseData = await this.$store.dispatch(
        "Transactional/SourceOfFundsValues"
      );
      console.log("responseData", responseData.data);
      responseData.data.map((i) => {
        this.source_options.push({
          name: i.value,
          id: i.value_code,
        });

        // this.sourceList_objs.push(i);
      });
      if (this.is_update && this.combinedData?.source_of_fund_code.value_code) {
        this.addProfilePayload.source_of_fund_code =
          this.combinedData.source_of_fund_code.value_code;
      }
      console.log("this.source_options", this.source_options);
    },
    async getnatureOfBusiness() {
      const responseData = await this.$store.dispatch(
        "Transactional/getnatureOfBusiness"
      );
      console.log("responseData", responseData.data);
      responseData.data.map((i) => {
        this.natureofbusList.push({
          name: i.value,
          id: i.value_code,
        });

        // this.sourceList_objs.push(i);
      });
      if (this.is_update && this.combinedData?.nature_of_business.value_code) {
        this.addProfilePayload.nature_of_business =
          this.combinedData.nature_of_business.value_code;
      }
      console.log("this.source_options", this.source_options);
    },
    async getPostalCodes() {
      try {
        const responseData = await this.$store.dispatch(
          "Transactional/getPostalCodes"
        );
        if (responseData.data.status_code === 200) {
          this.postalCodeslist = [...responseData.data.data];
        }
        console.log("postalCodes", responseData);
      } catch (err) {
        console.log(err);
      }
    },

    async getCountryList() {
      const responseData = await this.$store.dispatch(
        "Remittee/getNationalityList"
      );
      console.log("responseData", responseData.data);
      responseData.data.data.map((i) => {
        this.country_options.push({
          name: i.country_name,
          id: i.iso_alpha3,
        });
        // this.onChangeCounty('MYS');
      });
      console.log("country_options", this.country_options);
    },

    async onChangeCounty(iso3) {
      await this.$store.dispatch("Authentication/getStateByIso3", iso3);
      if (Array.isArray(this.state_list.response.data)) {
        this.state_options = this.state_list.response.data.map((i) => {
          return {
            name: i.name,
            id: i.state_code,
          };
        });
        // this.onChangeState(this.state_options[0].id);
        console.log("stae", this.state_options);
      }
      if (this.is_update && this.combinedData.mailing_state) {
        this.onChangeState(this.combinedData.mailing_state.state_code);
      }
      if (this.is_update && this.combinedData.state_code) {
        this.onChangemailingState(this.combinedData.state_code.state_code);
      }
    },

    async onChangemailingState(state) {
      console.log("state", state);
      let id = {
        state: state,
        country: this.addressInfo.mailing_country,
      };
      await this.$store.dispatch("Authentication/getCityByIso3", id);
      if (Array.isArray(this.city_list.response.data)) {
        this.maling_city_options = this.city_list.response.data.map((i) => {
          return {
            val: i.id,
            txt: i.name,
          };
        });
      }
      // if (this.combinedData.mailing_city) {
      //   console.log("city", this.city_options);
      //   this.addressInfo.mailing_city = parseInt(
      //     this.combinedData.mailing_city
      //   );
      // }
      if (this.is_update && this.combinedData.city_code.id) {
        this.businessAddress.city_code = parseInt(
          this.combinedData.city_code.id
        );
      }
      // if (this.city_options.length === 0) {
      //   this.haveCities = false;
      // } else {
      //   this.haveCities = true;
      //   console.log("have list");
      // }
    },
    async onChangeState(state) {
      console.log("state", state);
      let id = {
        state: state,
        country: this.addressInfo.mailing_country,
      };
      await this.$store.dispatch("Authentication/getCityByIso3", id);
      if (Array.isArray(this.city_list.response.data)) {
        this.city_options = this.city_list.response.data.map((i) => {
          return {
            val: i.id,
            txt: i.name,
          };
        });
      }
      console.log(
        "city combinedData",
        this.combinedData.mailing_city.id,
        this.is_update
      );
      if (this.is_update && this.combinedData.mailing_city.id) {
        console.log("city", this.city_options);
        this.addressInfo.mailing_city = parseInt(
          this.combinedData.mailing_city.id
        );
      }
      // if (this.combinedData.city_code) {
      //   this.businessAddress.city_code = parseInt(this.combinedData.city_code);
      // }
      // if (this.city_options.length === 0) {
      //   this.haveCities = false;
      // } else {
      //   this.haveCities = true;
      //   console.log("have list");
      // }
    },

    async getEntityType() {
      try {
        const token = await sessionStorage.getItem("access_token");
        const responseData = await axios.get(
          `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-dropdown?parameter_code=ETD`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        responseData.data.data.map((i) => {
          this.entityType_options.push({
            name: i.value,
            id: i.value_code,
          });

          // this.sourceList_objs.push(i);
        });
        // console.log(`responseData: ${JSON.stringify(responseData.data.data)}`)

        console.log("entityType_options", this.entityType_options);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";

.invalid-feedback-name {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
</style>
