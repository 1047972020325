<template>
  <v-card flat>
    <v-alert
      v-if="alertMsg.status"
      :timeout="alertMsg.timeout"
      :color="alertMsg.color"
      :type="alertMsg.type"
      dismissible
    >
      {{ alertMsg.text }}
    </v-alert>
    <v-card-title class="pa-2 ma-0">
      <span class="text-h6">{{ $t("individual_share_holder") }}</span>
    </v-card-title>
    <v-divider class="p-0 m-0"></v-divider>
    <v-card-text>
      <v-container>
        <v-form v-model="valid" ref="busApplicant">
          <v-row>
            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0"
                >{{ $t("fullName") }}<span style="color: #f00">*</span></label
              >
              <v-text-field
                v-model="editedItem.full_name"
                :error-messages="fnameErrors"
                @input="$v.editedItem.full_name.$touch()"
                dense
                :placeholder="$t('fullName')"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0">{{ $t("Gender") }}</label>
              <v-autocomplete
                :placeholder="$t('gender_placeholder')"
                :items="genderOptions"
                v-model="editedItem.gender"
                dense
                outlined
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0">{{
                $t("date_of_birth_business")
              }}</label>

              <v-menu
                ref="dobmenu"
                v-model="dobmenu"
                :close-on-content-click="false"
                lazy
                transition="scale-transition"
                :offset-x="true"
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="editedItem.dob"
                    persistent-hint
                    clearable
                    v-on="on"
                    type="date"
                    dense
                    @change="ValidateAge"
                    :min="minDate"
                    :max="maxDate"
                    outlined
                  >
                  </v-text-field>
                </template>
              </v-menu>
            </v-col>

            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0">{{
                $t("country_of_birth_business")
              }}</label>
              <v-autocomplete
                :placeholder="$t('country_of_birth_placeholder')"
                :items="country_options"
                item-text="name"
                item-value="id"
                v-model="editedItem.country_code"
                outlined
                required
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0"
                >{{ $t("Nationality")
                }}<span style="color: #f00">*</span></label
              >
              <v-autocomplete
                :placeholder="$t('nationality_placeholder')"
                dense
                v-model="editedItem.nationality_code"
                :items="nationality_options"
                item-text="name"
                item-value="id"
                :error-messages="nationalityErrors"
                @input="$v.editedItem.nationality_code.$touch()"
                outlined
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0"
                >{{ $t("Occupation") }}<span style="color: #f00">*</span></label
              >
              <v-autocomplete
                v-model="editedItem.occupation_code"
                :items="occupation_options"
                item-text="name"
                item-value="id"
                :error-messages="designationErrors"
                @input="$v.editedItem.occupation_code.$touch()"
                :placeholder="$t('occupation_placeholder')"
                dense
                outlined
                required
              ></v-autocomplete>
            </v-col>
            <!-- <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0"
                >{{ $t("designation_label")
                }}<span style="color: #f00">*</span></label
              >
              <v-autocomplete
                v-model="editedItem.occupation_code"
                :items="occupation_options"
                item-text="name"
                item-value="id"
                :error-messages="designationErrors"
                @input="$v.editedItem.occupation_code.$touch()"
                :placeholder="$t('designation_label')"
                dense
                outlined
                required
              ></v-autocomplete>
            </v-col> -->

            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0"
                >{{ $t("emailAddress")
                }}<span style="color: #f00">*</span></label
              >
              <v-text-field
                v-model="editedItem.email_id"
                :error-messages="emailErrors"
                @input="$v.editedItem.email_id.$touch()"
                :placeholder="$t('email_placeholder')"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0">{{ $t("address_beneficary") }}</label>
              <v-text-field
                :placeholder="$t('address_beneficary')"
                outlined
                required
                dense
                v-model="editedItem.address"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0">{{ $t("phoneNumber") }}</label>
              <vue-phone-number-input
                default-country-code="MY"
                @update="onChangeNumber"
                :error="!isValidNumber"
                v-model="editedItem.phone_number"
                class=""
                required
              />
              <!-- <span style="color: #ff5252; font-size: 12px">{{
                          contactNoErrors[0]
                        }}</span> -->
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0">{{ $t("EmployerName") }}</label>
              <v-text-field
                :placeholder="$t('EmployerName')"
                outlined
                required
                dense
                v-model="editedItem.employer"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label class="ma-0 pa-0"
                >{{ $t("share_holder_percentage")
                }}<span style="color: #f00">*</span></label
              >
              <v-text-field
                :placeholder="$t('share_holder_percentage')"
                v-model="editedItem.percentage_of_shares"
                suffix="%"
                type="number"
                :rules="validations.sharesRules"
                outlined
                required
                hide-spin-buttons
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label class="ma-0 pa-0"
                >{{ $t("no_of_shares")
                }}<span style="color: #f00">*</span></label
              >
              <v-text-field
                :placeholder="$t('no_of_shares')"
                v-model="editedItem.no_of_shares"
                type="number"
                :rules="validations.NoofsharesRules"
                outlined
                required
                hide-spin-buttons
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0"
                >{{ $t("idType") }}<span style="color: #f00">*</span></label
              >
              <v-select
                v-model="editedItem.id_type_code"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="validations.id_proofrules"
                :items="idtypes_val"
                item-text="name"
                item-value="id"
                outlined
                required
                :placeholder="$t('idtype_select_placeholder')"
                dense
              ></v-select>
              <!-- <v-select
                    v-else
                    v-model="id_type_back"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :rules="validations.id_proofrules"
                    :items="idtypes_val"
                    item-text="name"
                    item-value="id"
                    :disabled="disableValues || disableBystatus"
                    outlined
                    required
                    :placeholder="$t('idtype_select_placeholder')"
                    dense
                  ></v-select> -->
            </v-col>

            <v-col
              cols="12"
              md="6"
              v-if="
                editedItem.id_type_code != 'MYKAD' &&
                editedItem.id_type_code != 'MyPR'
              "
            >
              <label class="ma-0 pa-0"
                >{{ $t("isuueDate") }}<span style="color: #f00">*</span></label
              >
              <v-text-field
                v-model="editedItem.id_issue_date"
                :rules="validations.issuedaterules"
                dense
                required
                outlined
                type="date"
                :max="currentDate"
              />
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0"
                >{{ $t("Location")
                }}<span
                  v-if="editedItem.id_type_code === 'Passport'"
                  style="color: #f00"
                  >*</span
                ></label
              >
              <v-autocomplete
                v-model="editedItem.id_issue_country"
                item-text="name"
                item-value="id"
                :items="country_options"
                :rules="validations.issue_locationRules"
                :placeholder="$t('Location')"
                dense
                outlined
                required
                :min="currentDate"
              />
            </v-col>

            <v-col
              cols="12"
              md="6"
              v-if="
                editedItem.id_type_code != 'MYKAD' &&
                editedItem.id_type_code != 'MyPR'
              "
            >
              <label class="ma-0 pa-0"
                >{{ $t("expiryDate") }}<span style="color: #f00">*</span></label
              >
              <v-text-field
                v-model="editedItem.id_expiry_date"
                :rules="validations.expDateRules"
                dense
                outlined
                required
                type="date"
                :min="currentDate"
              />
            </v-col>

            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0"
                >{{ $t("idNumber") }}<span style="color: #f00">*</span></label
              >
              <v-text-field
                v-model="editedItem.id_number"
                :rules="validations.id_numberRules"
                :placeholder="$t('id_number_placeholder')"
                outlined
                required
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0"
                >{{ $t("IDFirstPage")
                }}<span style="color: #f00">*</span></label
              >
              <v-file-input
                v-model="editedItem.ID_First_Page"
                :rules="validations.firstpage_rules"
                dense
                accept="image/png, image/jpeg, image/bmp"
                :placeholder="$t('idtype_first_page_placeholder')"
                outlined
                ref="idfirstfile"
                required
                prepend-icon=""
              >
                <template v-slot:append>
                  <v-icon
                    aria-hidden="false"
                    class="color-primary"
                    @click="openIdfirst"
                  >
                    mdi-cloud-upload
                  </v-icon>
                </template>

                <template v-slot:append-outer>
                  <v-img
                    v-if="frontPageUrl && editedItem.ID_First_Page"
                    class="ml-1"
                    max-height="40"
                    max-width="50"
                    :src="frontPageUrl"
                    @click="handleImageViewer(frontPageUrl)"
                  />
                </template>
              </v-file-input>
            </v-col>

            <v-col cols="12" md="6" class="pt-0">
              <label class="ma-0 pa-0">{{ $t("IDLastPage") }}</label>
              <v-file-input
                v-model="editedItem.ID_Last_Page"
                dense
                accept="image/png, image/jpeg, image/bmp"
                :placeholder="$t('idtype_last_page_placeholder')"
                outlined
                ref="idlastfile"
                required
                prepend-icon=""
              >
                <template v-slot:append>
                  <v-icon
                    aria-hidden="false"
                    class="color-primary"
                    @click="openIdlast"
                  >
                    mdi-cloud-upload
                  </v-icon>
                </template>

                <template v-slot:append-outer>
                  <v-img
                    v-if="backPageUrl && editedItem.ID_Last_Page"
                    class="ml-1"
                    max-height="40"
                    max-width="50"
                    :src="backPageUrl"
                    @click="handleImageViewer(backPageUrl)"
                  />
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-divider class="p-o m-0"></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        @click="close"
        v-if="!editedItem.biz_user_id"
      >
        {{ $t("cancel_label") }}
      </v-btn>
      <v-btn
        color="primary"
        @click="onClickNext()"
        v-if="!editedItem.biz_user_id"
        >{{ $t("save") }}</v-btn
      >
      <v-btn
        color="primary"
        outlined
        @click="close"
        v-if="editedItem.biz_user_id"
      >
        {{ $t("cancel_label") }}
      </v-btn>
      <v-btn color="primary" @click="update()" v-if="editedItem.biz_user_id">
        {{ $t("update_label") }}</v-btn
      >
    </v-card-actions>
    <image-viewer
      :showDialog="isImageViewer"
      :picture="{
        PicHeight: '435',
        PicWidth: 600,
        Pic: imageViewerUrl,
      }"
      @closeImageViewer="handleCloseImageViewer"
    ></image-viewer>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { i18n as $ } from "../../../locale/index";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { email, required } from "vuelidate/lib/validators";
import { findDatePrevious } from "../../../utils/helper";
import { alpha } from "../../../utils/validate";
import { BusUserTypes } from "../../../utils/BusDocuments";
import validatemixin from "@/mixins/Busvalidate.mixin";
import axios from "axios";
import imageViewer from "../../tools/imageViewer.vue";
import handleImageUpload from "../../../mixins/compressImage.mixin";
// import { issueDate, expireDate } from "../../../utils/validate";

export default {
  mixins: [validatemixin, handleImageUpload],
  data: () => ({
    id_type: "",
    dialog: false,
    valid: true,
    currentDate: new Date().toJSON().split("T")[0],
    buscheckboxes: BusUserTypes(),
    Entitycheckbox: [],
    validationFields: [],
    backPageUrl: null,
    frontPageUrl: null,
    dialogDelete: false,
    dobmenu: false,
    kycStatus: null,
    disableBystatus: null,
    id_type_back: null,
    issue_location: null,
    maxDate: "",
    minDate: "",
    isDobError: false,
    expriydate: false,
    isValidNumber: false,
    country_options: [],
    relation_options: [],
    idtypes: [],
    idtypes_val: [],
    idtype_objs: [],
    nationality_options: [],
    occupation_options: [],
    occupation_objs: [],
    genderOptions: [
      { text: "Male", value: "M" },
      { text: "Female", value: "F" },
    ],
    isSubmitted: false,
    isUploading: false,
    alertMsg: {
      status: false,
      text: "",
      timeout: 2000,
      color: "blue-grey",
      type: "info",
    },
    countrySelected: {},
    headers: [
      {
        text: "Full Name",
        align: "start",
        sortable: false,
        value: "full_name",
      },
      { text: "Type", value: "type" },
      { text: "Email", value: "email_id" },
      { text: "ID type", value: "id_type_code" },
      { text: "ID number", value: "id_number" },
      { text: "First page", value: "first_page" },
      { text: "Last page", value: "last_page" },
      { text: "Actions", value: "actions" },
    ],
    getinformation: [],
    editedIndex: -1,
    biz_user_id: "",
    imageViewerUrl: "",
    isImageViewer: false,
    userId: null,
    editedItem: {
      full_name: "",
      gender: "",
      dob: null,
      email_id: "",
      phone_number: "",
      nationality_code: "",
      occupation_code: "",
      country_code: "",
      address: "",
      employer: "",
      percentage_of_shares: null,
      type: "Shareholder",
      // applicant_country_code: "",
      id_type_code: null,
      id_number: null,
      ID_First_Page: null,
      ID_Last_Page: null,
      no_of_shares: null,
      id_issue_date: null,
      id_expiry_date: null,
      id_issue_country: "Malaysia",
    },
    deleteId: "",
    validations: {
      selfy_rules: [(v) => !!v || $.t("file_select_placeholder")],
      firstpage_rules: [(v) => !!v || $.t("file_select_placeholder")],
      issuedaterules: [(v) => !!v || $.t("issueDateMsg1")],
      issue_locationRules: [],
      expDateRules: [(v) => !!v || $.t("expiryDateMsg1")],
      id_proofrules: [(v) => !!v || $.t("idtypeMsg1")],
      id_numberRules: [(v) => !!v || $.t("idnumberMsg1")],
      interviewData: [(v) => !!v || $.t("interviewDate")],
      interviewTime: [(v) => !!v || $.t("interviewTime")],
      sharesRules: [
        (v) => !!v || $.t("shares_percentage"),
        (v) => v <= 100 || $.t("no_ofsharesmsg"),
      ],
      NoofsharesRules: [(v) => !!v || $.t("no_ofshares")],
    },
  }),
  name: "BasicDetails",
  components: {
    VuePhoneNumberInput,
    imageViewer,
  },
  props: {
    selctedEntity: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("Transactional", [
      "realtion_list",
      "nationality_list",
      "occupation_list",
    ]),
    ...mapGetters("Authentication", ["country_list"]),
    applicantErrors() {
      const errors = [];
      if (!this.$v.editedItem.applicant_type.$dirty) return errors;
      !this.$v.editedItem.applicant_type.required && errors.push("required");
      return errors;
    },
    fnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.full_name.$dirty) return errors;
      !this.$v.editedItem.full_name.required &&
        errors.push(this.$t("full_name"));
      !this.$v.editedItem.full_name.alpha &&
        errors.push(this.$t("invalid_full_name"));
      return errors;
    },

    designationErrors() {
      const errors = [];
      if (!this.$v.editedItem.occupation_code.$dirty) return errors;
      !this.$v.editedItem.occupation_code.required &&
        errors.push(this.$t("select_occupation"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editedItem.email_id.$dirty) return errors;
      !this.$v.editedItem.email_id.required &&
        errors.push(this.$t("email_errors"));
      !this.$v.editedItem.email_id.email &&
        errors.push(this.$t("invalid_email"));
      return errors;
    },

    nationalityErrors() {
      const errors = [];
      if (!this.$v.editedItem.nationality_code.$dirty) return errors;
      !this.$v.editedItem.nationality_code.required &&
        errors.push(this.$t("nationality"));
      return errors;
    },

    DOBerrors() {
      const errors = [];
      if (!this.$v.editedItem.dob.$dirty) return errors;
      !this.$v.editedItem.dob.required && errors.push(this.$t("select_dob"));
      if (this.isDobError) {
        errors.push(this.$t("invalid_dob"));
      }
      return errors;
    },
  },

  watch: {
    "editedItem.applicant_type"(applicant_type) {
      console.log("applicant_type", applicant_type);
      this.validationFields = [...this.getRequired(applicant_type)];
      console.log("applicant_type:", this.getRequired(applicant_type));
    },

    "editedItem.id_type"(val) {
      this.idtype_objs.map((i) => {
        if (val === i.parameter_value) {
          this.id_type_code = i.parameter_code;
        }
      });
    },

    id_type(val) {
      this.idtype_objs.map((i) => {
        if (val === i.value) {
          this.id_type_code = i.id;
        }
      });
      if (val == "MYKAD" || val == "MyPR") {
        this.editedItem.id_issue_country = "Malaysia";
        this.disableIssueLoc = true;
      } else {
        // this.user_details.issue_location = "";
        this.disableIssueLoc = false;
      }
      if (val === "Passport") {
        this.validations.issue_locationRules = [
          (v) => !!v || $.t("issue_location_placeholder"),
        ];
      } else {
        this.validations.issue_locationRules = [];
      }
    },

    "editedItem.issue_location"(val) {
      this.country_objs.map((i) => {
        if (val === i.name) {
          this.issue_location_code = i.iso3;
        }
      });
    },

    "editedItem.id_issue_date"(val) {
      if (!val < new Date().toJSON().split("T")[0]) {
        this.validations.issuedaterules.push($.t("issue_date_valid"));
      } else {
        this.validations.issuedaterules = [
          (v) => !!v || $.t("issueDateMsg1"),
        ];
      }
    },
    "editedItem.id_expiry_date"(val) {
      if (!val > new Date().toJSON().split("T")[0]) {
        this.validations.expDateRules.push($.t("expiry_date_issue_validate"));
      } else {
        this.validations.expDateRules = [
          (v) => !!v || $.t("expiryDateMsg1"),
        ];
      }
    },

    countrySelected(newVal) {
      console.log("Selected country", newVal);
      if (Object.keys(newVal).length > 0) {
        this.editedItem.country_code = newVal.name;
        this.editedItem.applicant_country_code = newVal.iso3;
      }
    },
  },

  created() {
    // this.initialize();
  },
  validations() {
    return {
      editedItem: {
        applicant_type: {
          required,
        },
        full_name: {
          required,
          alpha,
        },
        nationality_code: {
          required,
        },
        occupation_code: {
          required,
        },
        email_id: {
          required,
          email,
        },
      },
    };
  },
  async mounted() {
    await this.getRelationship();
    await this.getCountryList();
    await this.getOccupation();
    await this.getNationalityList();
    await this.getidtypes();
    await this.getapplicant();

    this.maxDate = findDatePrevious(new Date(), 18);
    this.minDate = findDatePrevious(new Date(), 70);
  },

  methods: {
    close() {
      this.$emit("closeDialog");
    },
    handleCloseImageViewer() {
      this.isImageViewer = !this.isImageViewer;
      this.imageViewerUrl = "";
    },
    handleImageViewer(url) {
      this.isImageViewer = !this.isImageViewer;
      this.imageViewerUrl = url;
    },

    getapplicant() {
      this.isLoading = true;
      axios({
        url: `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/all-applicant-information`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      })
        .then((response) => {
          this.isLoading = false;

          if (response.status === 200) {
            this.getinformation = response.data.data.reverse();
            // const getinform = response.data.data[0];
          }

          console.log("getdata", this.getinformation);
        })

        .catch((e) => {
          this.isLoading = false;
          console.error(e);
        });
    },

    async getOccupation() {
      await this.$store.dispatch("Transactional/getOccupation");
      console.log("Occupation", this.occupation_list.occupation.data);
      // console.log(`responseData: ${JSON.stringify(this.occupation_list.occupation.data[0])}`);
      if (Array.isArray(this.occupation_list.occupation.data)) {
        this.occupation_options =
          await this.occupation_list.occupation.data.map((i) => {
            return {
              name: i.value,
              id: i.value_code,
            };
          });

        this.occupation_list.occupation.data.map((i) => {
          this.occupation_objs.push(i);
        });

        console.log("this", this.editedItem.occupation_code);
      }
    },
    async onFileChange(e) {
      console.log(e);
      const files = e.target.files || e.dataTransfer.files;

      this.IDfileName = files[0].name;
      const idpreviewImage = await this.getBase64(files[0]);
      this.idpreviewImage = idpreviewImage;
      const extractBase64 = idpreviewImage.split(",").reverse()[0];
      this.user_details.fileUpload = extractBase64;

      let fileData = new FormData();
      fileData.append("document", files[0]);
      console.log(fileData.document);
      try {
        console.log("inside of the try ");
        const responseData = await this.$store.dispatch(
          "Authentication/ekycDocSubmit", //flag for upload
          fileData
        );
        if (responseData.status === 200) {
          console.log("test uploaded");
        } else {
          this.errorMessage = responseData.response.data.message;
          this.dismissCountDownError = this.dismissSecs;
        }
      } catch (error) {
        this.dismissCountDownError = this.dismissSecs;
        this.loader = false;
        this.errorMessage = "Server API is not working Please wait sometime";
      }
    },
    async fileUpload(fileObject, biz_user_id, doc_type) {
      this.loader = true;
      let compressdata = await this.handleImageUpload(fileObject);
      console.log(compressdata);
      const token = sessionStorage.getItem("access_token");
      let fileData = new FormData();
      fileData.append("document", compressdata);
      try {
        const responseData = await axios({
          method: "POST",
          url: `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/applicant-information/upload-docs?biz_user_id=${biz_user_id}&doc_type=${doc_type}`,
          data: fileData,
          headers: {
            ContentType: "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          // params: { doc_type },
        });

        if (responseData && responseData.data.status_code == 200) {
          console.log("responseData", responseData);
          this.$emit("getapplicant");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getidtypes() {
      let response = await this.$store.dispatch("Transactional/getIDtypes");
      response.data.data.map((idtype) => {
        this.idtypes.push(idtype.value);
        this.idtypes_val.push({
          name: idtype.value,
          id: idtype.value_code,
        });
        this.idtype_objs.push(idtype);
      });
      console.log("typein", this.idtypes);
    },

    open() {
      this.$refs.selfiefile.$el.querySelector("input").click();
    },
    openIdlast() {
      this.$refs.idlastfile.$el.querySelector("input").click();
    },
    openIdfirst() {
      this.$refs.idfirstfile.$el.querySelector("input").click();
    },
    ValidateAge(value) {
      if (!value) this.isDobError = false;
      else this.isDobError = !(value <= this.maxDate && value >= this.minDate);
    },
    onChangeNumber(e) {
      this.isValidNumber = e.isValid;
    },

    async getCountryList() {
      const responseData = await this.$store.dispatch(
        "Remittee/getNationalityList"
      );
      console.log("responseData", responseData.data);
      responseData.data.data.map((i) => {
        this.country_options.push({
          name: i.country_name,
          id: i.iso_alpha3,
        });
        // this.onChangeCounty('MYS');
      });
      console.log("country_options", this.country_options);
    },
    async getRelationship() {
      await this.$store.dispatch("Transactional/getRelationship");
      if (Array.isArray(this.realtion_list.response.data)) {
        this.relation_options = await this.realtion_list.response.data.map(
          (i) => i.value
        );
      }
    },

    async getNationalityList() {
      const responseData = await this.$store.dispatch(
        "Remittee/getNationalityList"
      );
      console.log("nationality,", responseData);
      responseData.data.data.map((i) => {
        this.nationality_options.push({
          name: i.nationality,
          id: i.iso_alpha3,
        });
      });
    },

    editItem(item) {
      this.editedIndex = this.getinformation.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.applicant_type = item.type;
      console.log("image data", item.type);
      this.idtype_objs.map((ele) => {
        console.log("ele", ele);
        if (ele.value == item.id_type_code) {
          this.editedItem.id_type_code = ele.value_code;
        }
      });
      this.editedItem.ID_First_Page = item.first_page
        ? {
            name: item.first_page?.split("/").pop(),
            value: item.first_page,
          }
        : null;

      this.editedItem.ID_Last_Page = item.last_page
        ? {
            name: item.last_page?.split("/").pop(),
            value: item.last_page,
          }
        : null;
      this.backPageUrl = item.last_page ? item.last_page : null;
      this.frontPageUrl = item.first_page ? item.first_page : null;
      this.editedItem.occupation_code = item.occupation_code
        ? item.occupation_code
        : null;
      // this.getapplicant();
      this.dialog = true;
    },

    deleteItem(item) {
      this.dialogDelete = true;
      this.deleteId = item.biz_user_id;
    },

    onClickNext() {
      // console.log(`editedItem: ${JSON.stringify(this.editedItem)}`);
      // this.$v.$touch();
      // if (this.$v.$invalid && this.$refs.busApplicant.validate()) {
      //   return;
      // }
      if (this.$refs.busApplicant.validate()) {
        this.save();
      }
    },

    onClickBack() {
      this.$emit("onClickBack");
    },

    async save() {
      this.loader = true;
      this.editedItem.no_of_shares = +this.editedItem.no_of_shares;
      this.editedItem.percentage_of_shares =
        +this.editedItem.percentage_of_shares;
      try {
        const responseData = await axios.post(
          `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/add-authorization-ind-person-information`,
          this.editedItem,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );

        console.log("responseData", responseData);

        if (
          responseData &&
          responseData.data &&
          responseData.data.success === true
        ) {
          this.userId = responseData.data.data[0].biz_user_id;
          console.log(" this.userId", this.userId);
          console.log("ll", responseData);
          this.alertMsg.status = true;
          this.alertMsg.text = responseData.data.message;
          this.alertMsg.type = "success";
          this.alertMsg.color = "green";
          this.fileUpload(
            this.editedItem.ID_First_Page,
            responseData.data.data[0].biz_user_id,
            "ID_First_Page"
          );
          // let ID_Last_Page = new FormData();
          // ID_Last_Page.append("document", this.editedItem.ID_Last_Page);
          this.fileUpload(
            this.editedItem.ID_Last_Page,
            responseData.data.data[0].biz_user_id,
            "ID_Last_Page"
          );
          this.$emit("getapplicant");
        } else {
          this.alertMsg.status = true;
          this.alertMsg.text = `Creation Failed: ${responseData.data.message}`;
          this.alertMsg.type = "error";
          this.alertMsg.color = "warning";
          // alert("iam king")
        }
      } catch (error) {
        this.alertMsg.status = true;
        this.alertMsg.text = `Creation Failed: please try again sometime`;
        this.alertMsg.type = "error";
        this.alertMsg.color = "error";
        // alert("jj")
      }
      this.close();
    },

    update() {
      this.$v.$touch();
      if (this.$v.$invalid && this.$refs.busApplicant.validate()) {
        return;
      }
      if (this.$refs.busApplicant.validate()) {
        this.updateData();
      }
    },

    updateData() {
      this.loader = true;
      if (this.editedIndex < -1) {
        Object.assign(this.getinformation[this.editedIndex], this.editedItem);
      } else {
        axios
          .put(
            `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/update-applicant-information?biz_user_id=${this.editedItem.biz_user_id}`,
            this.editedItem,

            {
              headers: {
                ContentType: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem(
                  "access_token"
                )}`,
                accept: "application/json",
              },
            }
          )
          .then((responseData) => {
            console.log(`responseData: ${JSON.stringify(responseData)}`);
            if (responseData && responseData.data.status_code == 200) {
              this.alertMsg.status = true;
              this.alertMsg.text = responseData.data.message;
              this.alertMsg.type = "success";
              this.alertMsg.color = "green";
              this.fileUpload(
                this.editedItem.ID_First_Page,
                responseData.data.data[0].biz_user_id,
                "ID_First_Page"
              );
              // let ID_Last_Page = new FormData();
              // ID_Last_Page.append("document", this.editedItem.ID_Last_Page);
              this.fileUpload(
                this.editedItem.ID_Last_Page,
                responseData.data.data[0].biz_user_id,
                "ID_Last_Page"
              );
              this.$emit("getapplicant");
              this.close();
            } else {
              this.alertMsg.status = true;
              this.alertMsg.text = `Update Failed: ${responseData.data.message}`;
              this.alertMsg.type = "error";
              this.alertMsg.color = "warning";
            }
            console.log("respose data", responseData);
          })
          .catch((error) => {
            console.log(error.response);
            this.alertMsg.status = true;
            this.alertMsg.text = `Update Failed: please try again sometime`;
            this.alertMsg.type = "error";
            this.alertMsg.color = "error";
          });
        setTimeout(() => {
          this.loader = false;
        }, 300);
      }
    },
  },
};
</script>
<style>
.v-input .v-label {
  margin-bottom: 0px !important;
}
</style>
