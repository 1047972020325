<template>
  <v-container>
    <loading
      :active="loader"
      :is-full-page="true"
      :opacity="0.9"
      color="#ff6b00"
    />
    <v-alert
      v-if="alertMsg.status"
      :timeout="alertMsg.timeout"
      :color="alertMsg.color"
      :type="alertMsg.type"
      dismissible
    >
      {{ alertMsg.text }}
    </v-alert>
    <v-row>
      <v-col cols="12" md="6">
        <p class="font-weight-bold text-h5">{{ selctedEntity }}:</p>
      </v-col>
      <v-col cols="12" md="6" v-if="allSets.includes(selctedEntity)">
        <v-radio-group v-model="docUploadType" row>
          <v-radio
            :label="$t('all_docs_together')"
            color="primary"
            value="alldocs"
          ></v-radio>
          <v-radio
            :label="$t('individual_docs')"
            color="primary"
            value="individualdocs"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-for="doc in EntityDocs" :key="doc.doc_name.name">
      <v-col cols="12" md="8">{{ doc.doc_name.name }} </v-col>
      <v-btn color="success" fab small class="ma-2" v-if="doc.doc_val"
        ><v-icon @click="openDoc(doc.doc_val)">
          mdi-file-download</v-icon
        ></v-btn
      >

      <v-menu
        v-model="doc.isactive"
        :close-on-content-click="false"
        fixed
        :nudge-width="400"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            class="ma-2"
            dark
            v-bind="attrs"
            v-on="on"
            fab
            small
          >
            <v-icon dark small>
              {{ doc.doc_val ? "mdi-pencil" : "mdi-cloud-upload" }}</v-icon
            >
          </v-btn>
        </template>

        <v-card>
          <v-form ref="uploadDoc">
            <v-col cols="12"
              >{{ doc.doc_name.name }}<span style="color: #f00">*</span>
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="doc.doc_val"
                dense
                accept="application/pdf"
                :placeholder="$t('upload_file')"
                :error-messages="docError"
                outlined
                required
                prepend-icon=""
                @change="fileUpload($event)"
              >
                <template v-slot:append>
                  <v-icon aria-hidden="false" class="color-primary">
                    mdi-cloud-upload
                  </v-icon>
                </template>
              </v-file-input>
            </v-col>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text @click="doc.isactive = false">
                {{ $t("cancel_label") }}</v-btn
              >
              <v-btn color="primary" text @click="uploadDocument(doc)">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-menu>
    </v-row>
  </v-container>
</template>

<script>
import { webbreakdown } from "../../utils/breakpoints";
import Loading from "vue-loading-overlay";
import { IndbusinessDocs, SetbusinessDocs } from "../../utils/BusDocuments";
import axios from "axios";
export default {
  data: () => ({
    webbreakdown,
    businessDocument: IndbusinessDocs(),
    SetBusinessdocs: SetbusinessDocs(),
    allSets: [
      "Private Limited Companies (Sdn Bhd)",
      "Sole-Proprietorship / Partnership",
      "Limited Liability Partnership",
    ],

    showPopup: false,
    EntityDocs: null,
    loader: false,
    dialog: false,
    isshowdoc: false,
    SelectedSrc: null,
    EntityName: "",
    document_name: null,
    docError: "",
    docUploadType: "alldocs",
    alertMsg: {
      status: false,
      text: "",
      timeout: 2000,
      color: "blue-grey",
      type: "info",
    },
    selectedDoc: {},
    docsupload: {},
    recievedDocs: [],
    menu: false,
    error: false,
    docrules: [(v) => !!v || "Document is required"],
  }),
  components: {
    Loading,
  },
  name: "BankDetails",
  props: {
    selctedEntity: {
      type: String,
    },
    hideForm: {
      type: String,
    },
  },

  validations() {},
  watch: {
    selctedEntity: {
      handler(newval) {
        this.docUploadType = "alldocs";
        this.EntityName = newval;
        this.returnBusinessDocs(newval, this.SetBusinessdocs);
        console.log(" this.EntityName", newval);
      },
    },
    hideForm: {
      handler(newval) {
        if (
          newval &&
          this.EntityName === "Private Limited Companies (Sdn Bhd)"
        ) {
          this.docUploadType = "alldocs";
          this.returnBusinessDocs(this.EntityName, this.SetBusinessdocs);
        }
      },
    },
    docUploadType(val) {
      if (val === "alldocs") {
        this.returnBusinessDocs(this.selctedEntity, this.SetBusinessdocs);
      } else {
        this.returnBusinessDocs(this.selctedEntity, this.businessDocument);
      }
    },
  },
  mounted() {
    this.getuploads();
    this.returnBusinessDocs(this.selctedEntity, this.SetBusinessdocs);
  },
  computed: {},
  methods: {
    openDoc(docurl) {
      console.log(docurl);
      if (docurl) {
        this.loader = true;
        this.SelectedSrc = docurl;
        const url = docurl;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "excel.pdf");
        document.body.appendChild(link);
        link.click();
        this.loader = false;
        // this.isshowdoc = true;
      } else {
        return "";
      }
    },
    mounted() {
      this.getuploads();
    },
    getuploads() {
      this.loader = true;
      const token = sessionStorage.getItem("access_token");
      axios
        .get(
          `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/get-upload-documents`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log("pics", response);
          if (response.status === 200) {
            this.loader = false;
            this.docsupload = response.data.data[0];
            this.recievedDocs = this.EntityDocs.map((doc) => {
              for (let key in this.docsupload) {
                if (doc.doc_name.code === key) {
                  console.log("ele", this.docsupload[`${key}`]);
                  return {
                    doc_name: doc.doc_name,
                    doc_val: response.data.data[0][`${key}`],
                    doc_url: response.data.data[0][`${key}`],
                    isactive: false,
                  };
                }
              }
            });
            this.EntityDocs = this.recievedDocs;
            console.log("this.EntityDocs", this.EntityDocs);
          }
          console.log("docsupload", this.docsupload.ssm);
        });
      this.loader = false;
    },
    async uploadDocument(doc) {
      console.log("doc.doc_val", doc);
      if (doc.doc_val && !this.error) {
        this.docError = "";
        this.loader = true;
        const token = sessionStorage.getItem("access_token");
        let fileData = new FormData();
        fileData.append("document", doc.doc_val);
        try {
          const responseData = await axios({
            method: "POST",
            url: `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/upload-documents?type=${doc.doc_name.value}&doc_type=${doc.doc_name.value_code}`,
            data: fileData,
            headers: {
              ContentType: "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          });
          if (responseData && responseData.data.success) {
            this.loader = false;
            this.alertMsg.status = true;
            this.alertMsg.type = "success";
            this.alertMsg.text = this.$t("file_uploaded_successfully");
            this.alertMsg.color = "green";
            doc.isactive = false;
            this.getuploads();
          } else {
            this.docError = "";
            console.log("responseData", responseData);
            this.loader = false;
            this.alertMsg.status = true;
            this.alertMsg.type = "error";
            this.alertMsg.text = responseData.data.message;
            this.alertMsg.color = "warning";
          }
        } catch (error) {
          this.docError = "";
          console.log(error);
          this.loader = false;
          this.alertMsg.status = true;
          this.alertMsg.type = "error";
          this.alertMsg.text =
            "Error: Something went wrong.Please try again later.";
          this.alertMsg.color = "warning";
        }
      } else {
        this.docError = "Document is required";
      }
    },
    fileUpload(event) {
      console.log(event.name);
      if (event && event.name) {
        this.docError = "";
        this.error = false;
      } else {
        this.docError = "Document is required";
        this.error = true;
      }
    },
    open() {
      this.$refs.selfiefile.$el.querySelector("input").click();
    },
    returnBusinessDocs(val, documents) {
      for (let doc in documents) {
        if (doc === val) {
          console.log("doc", documents);
          this.EntityDocs = documents[doc].map((docval) => {
            return {
              doc_name: docval,
              doc_val: null,
              doc_url: null,
              isactive: false,
            };
          });
          console.log("thisthose", this.EntityDocs);
        }
        if (val === "Private Limited Companies (Sdn Bhd)") {
          this.EntityDocs = this.EntityDocs.filter((ele) => {
            if (ele.doc_name.code != this.hideForm) {
              return ele;
            }
          });
        }

        console.log("thisthat", this.EntityDocs);
      }
      this.getuploads();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";

.invalid-feedback-name {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
</style>
