// Function to show list of individual documnets in Documents Page
export function IndbusinessDocs() {
  return {
    "Private Limited Companies (Sdn Bhd)": [
      {
        name: "Form 49",
        code: "form_49",
        value_code: "2",
        value: "1",
      },
      {
        name: "Form 9",
        code: "form_9",
        value_code: "6",
        value: "1",
      },
      {
        name: "Form 44",
        code: "form_44",
        value_code: "4",
        value: "1",
      },
      {
        name: "Form 51",
        code: "form_51",
        value_code: "5",
        value: "1",
      },
      {
        name: "Memorandum and Articles of Association",
        code: "mmm",
        value_code: "7",
        value: "1",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "1",
      },
    ],
    "Sole-Proprietorship/Partnership": [
      {
        name: "Business Registration certificate (Form A or B)",
        code: "bus_reg_certificate ",
        value_code: "9",
        value: "2",
      },
      {
        name: "Business License (Form D)",
        code: "bus_license",
        value_code: "10",
        value: "2",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "2",
      },
    ],
    "Limited Liability Partnership": [
      {
        name: "Form 49",
        code: "form_49",
        value_code: "2",
        value: "3",
      },
      {
        name: "Form 44",
        code: "form_44",
        value_code: "4",
        value: "3",
      },
      {
        name: "Form 51",
        code: "form_51",
        value_code: "5",
        value: "3",
      },
      {
        name: "Memorandum and Articles of Association",
        code: "Memorandum and Articles of Association",
        value_code: "7",
        value: "3",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "3",
      },
    ],
    "Registered body": [
      {
        name: "Certificate of Incorporation/ Constitution",
        code: "cert_of_incorp_const",
        value_code: "12",
        value: "4",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "4",
      },
    ],
    Trust: [
      {
        name: "Trust deed",
        code: "trust_deed",
        value_code: "13",
        value: "5",
      },
      {
        name: "Trust registration document",
        code: "trust_registration",
        value_code: "14",
        value: "5",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "5",
      },
    ],
    "Public listed companies": [
      {
        name: "Company profile",
        code: "company_profile",
        value_code: "15",
        vale: "6",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "6",
      },
    ],
    GLC: [
      {
        name: "Company profile",
        code: "company_profile",
        value_code: "15",
        value: "7",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "7",
      },
    ],
    "Foreign public listed companies": [
      {
        name: "Company profile",
        code: "company_profile",
        value_code: "15",
        value: "8",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "8",
      },
    ],
    "Clubs/Societies/Charities": [
      {
        name: "Company profile",
        code: "company_profile",
        value_code: "15",
        value: "9",
      },
      {
        name: "Certificate of registration",
        code: "cert_of_reg",
        value_code: "17",
        value: "9",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "9",
      },
    ],
  };
}
// Function to show list of SET documnets in Documents Page
export function SetbusinessDocs() {
  return {
    "Private Limited Companies (Sdn Bhd)": [
      {
        name: "Full set of Corporate information from SSM (Form 49 / Form 24 / Form 44 / Form 51 or Form 9)",
        code: "ssm",
        value_code: "1",
        value: "1",
      },
      {
        name: "Memorandum and Articles of Association",
        code: "mmm",
        value_code: "7",
        value: "1",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "1",
      },
    ],
    "Sole-Proprietorship/Partnership": [
      {
        name: "Full set of Business profiles from SSM",
        code: "ssm",
        value_code: "1",
        value: "2",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "2",
      },
    ],
    "Limited Liability Partnership": [
      {
        name: "Full set of Corporate information from SSM (Form 49 / Form 24 / Form 44 / Form 51)",
        code: "ssm",
        value_code: "1",
        value: "3",
      },
      {
        name: "Memorandum and Articles of Association",
        code: "mmm",
        value_code: "7",
        value: "3",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "3",
      },
    ],
    "Registered body": [
      {
        name: "Certificate of Incorporation/ Constitution",
        code: "cert_of_incorp_const",
        value_code: "12",
        value: "4",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "4",
      },
    ],
    Trust: [
      {
        name: "Trust deed",
        code: "trust_deed",
        value_code: "13",
        value: "5",
      },
      {
        name: "Trust registration document",
        code: "trust_registration",
        value_code: "14",
        value: "5",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "5",
      },
    ],
    "Public listed companies": [
      {
        name: "Company profile",
        code: "company_profile",
        value_code: "15",
        vale: "6",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "6",
      },
    ],
    GLC: [
      {
        name: "Company profile",
        code: "company_profile",
        value_code: "15",
        value: "7",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "7",
      },
    ],
    "Foreign public listed companies": [
      {
        name: "Company profile",
        code: "company_profile",
        value_code: "15",
        value: "8",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "8",
      },
    ],
    "Clubs/Societies/Charities": [
      {
        name: "Company profile",
        code: "company_profile",
        value_code: "15",
        value: "9",
      },
      {
        name: "Certificate of registration",
        code: "cert_of_reg",
        value_code: "17",
        value: "9",
      },
      {
        name: "Representative letter",
        code: "rep_letter",
        value_code: "8",
        value: "9",
      },
    ],
  };
}
// Function to show list of user types and requirement of Authorization page
export function BusUserTypes() {
  return {
    "Private Limited Companies (Sdn Bhd)": {
      list: ["Board of directors", "Senior Management"],
      is_shareholder: true,
      is_authrequired: true,
    },
    "Sole-Proprietorship/Partnership": {
      list: ["Senior Management"],
      is_shareholder: true,
      is_authrequired: true,
    },
    "Limited Liability Partnership": {
      list: ["Senior Management"],
      is_shareholder: true,
      is_authrequired: true,
    },
    "Registered body": {
      list: ["Representative"],
      is_shareholder: false,
      is_authrequired: false,
    },
    Trust: {
      list: [
        "Settlor",
        "Trustee(s)",
        "Protector",
        "Beneficiaries",
        "other authorized person",
      ],
      is_shareholder: false,
      is_authrequired: true,
    },
    "Public listed companies": {
      list: ["Representative"],
      is_shareholder: false,
      is_authrequired: false,
    },
    GLC: {
      list: ["Representative"],
      is_shareholder: false,
      is_authrequired: false,
    },
    "Foreign public listed companies": {
      list: ["Representative"],
      is_shareholder: false,
      is_authrequired: false,
    },
    "Clubs/Societies/Charities": {
      list: [
        "List of Office bearer",
        "Chairman or president",
        "Treasurer",
        "Secretary",
      ],
      is_shareholder: false,
      is_authrequired: true,
    },
  };
}
export function entityTypes() {
  return {
    entityTypes: [
      {
        value_code: "1",
        value: "Private Limited Companies (Sdn Bhd)",
        id: 558,
      },
      {
        value_code: "2",
        value: "Sole-Proprietorship/Partnership",
        id: 559,
      },
      {
        value_code: "3",
        value: "Limited Liability Partnership",
        id: 560,
      },
      {
        value_code: "4",
        value: "Registered body",
        id: 561,
      },
      {
        value_code: "5",
        value: "Trust",
        id: 562,
      },
      {
        value_code: "6",
        value: "Public listed companies",
        id: 563,
      },
      {
        value_code: "7",
        value: "GLC",
        id: 564,
      },
      {
        value_code: "8",
        value: "Foreign public listed companies",
        id: 565,
      },
      {
        value_code: "9",
        value: "Clubs/Societies/Charities",
        id: 566,
      },
    ],
  };
}
