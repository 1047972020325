<template>
  <v-container>
    <loading
      :active="loader"
      :is-full-page="true"
      :opacity="0.9"
      color="#ff6b00"
    />
    <v-alert
      v-if="alertMsg.status"
      :timeout="alertMsg.timeout"
      :color="alertMsg.color"
      :type="alertMsg.type"
      dismissible
    >
      {{ alertMsg.text }}
    </v-alert>
    <v-row>
      <v-col cols="12" md="6">
        <label>{{ $t("bank_name_business") }}</label>
        <v-autocomplete
          :placeholder="$t('bank_name_placeholder')"
          v-model="addProfilePayload.bank_name"
          :items="bank_names"
          required
          outlined
          dense
          :error-messages="banknameerrors"
          @input="$v.addProfilePayload.bank_name.$touch()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <label>{{ $t("bank_account_no_business") }}</label>
        <v-text-field
          :placeholder="$t('bank_account_no_placeholder')"
          dense
          outlined
          required
          :error-messages="accountnumber"
          @input="$v.addProfilePayload.bank_account_number.$touch()"
          v-model="addProfilePayload.bank_account_number"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="12">
        <v-btn
          class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
          color="primary"
          @click="onClickNext"
          >{{ $t("Save") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import { bankNames } from "../../utils/banknames";
import { alpha } from "../../utils/validate";
import { required, numeric } from "vuelidate/lib/validators";

export default {
  data: () => ({
    bank_names: [...bankNames()],
    loader: false,
    addProfilePayload: {
      bank_name: "",
      bank_account_number: "",
      average_anual_turnover: "",
    },

    alertMsg: {
      status: false,
      text: "",
      timeout: 2000,
      color: "blue-grey",
      type: "info",
    },
  }),
  name: "BankDetails",
  components: {
    Loading,
  },
  mounted() {
    // if (this.$store.state.buscreatecountry) {
    //   this.getpayLocation("B", this.$store.state.buscreatecountry);
    // }
  },
  computed: {
    banknameerrors() {
      const errors = [];
      if (!this.$v.addProfilePayload.bank_name.$dirty) return errors;
      !this.$v.addProfilePayload.bank_name.required &&
        errors.push(this.$t("bank_name_required"));
      return errors;
    },

    accountnumber() {
      console.log("log", this.$v);
      const errors = [];
      if (!this.$v.addProfilePayload.bank_account_number.$dirty) return errors;
      !this.$v.addProfilePayload.bank_account_number.required &&
        errors.push(this.$t("bank_acc_numb_req"));
      !this.$v.addProfilePayload.bank_account_number.numeric &&
        errors.push(this.$t("only_type_numb"));
      return errors;
    },
  },

  validations() {
    return {
      addProfilePayload: {
        bank_name: {
          required,
          alpha,
        },
        bank_account_number: {
          required,
          numeric,
        },
      },
    };
  },

  watch: {
    "$store.state.buscreatecountry": function () {
      console.log(
        "$store.state.buscreatecountry",
        this.$store.state.buscreatecountry
      );
      if (this.$store.state.buscreatecountry) {
        this.getpayLocation("B", this.$store.state.buscreatecountry);
      }
    },
    country_code: {
      handler(newVal) {
        if (newVal) {
          this.getpayLocation("B", newVal);
        }
      },
    },
    // country_code(newVal) {
    //   console.log("country_code: ", newVal);
    //   this.getpayLocation("B", newVal);
    // },
  },

  created() {
    if (this.$store.state.buscreatecountry) {
      this.getpayLocation("B", this.$store.state.buscreatecountry);
    }
    const token = sessionStorage.getItem("access_token");
    axios
      .get(
        `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/get-other-data`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        this.addProfilePayload = {
          bank_name: response.data.data[0].bank_name,
          bank_account_number: response.data.data[0].bank_account_number,
        };
      });
  },

  methods: {
    onClickNext() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$emit("onNextClick", this.addProfilePayload);
      this.postList();
    },
    onClickBack() {
      this.$emit("onClickBack");
    },
    async getpayLocation(payment_mode, selected_country) {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios({
        url: `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-bank-list?country=${selected_country}&payment_mode=${payment_mode}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          accept: "application/json",
        },
      });
      console.log("res", responseData.data.data);
      if (responseData.status == 200) {
        this.bank_names = await responseData.data.data.map(
          (i) => i.locationName
        );
      } else {
        return;
      }
    },

    async postList() {
      console.log(`basicDetails: ${JSON.stringify(this.basicDetails)}`);
      this.loader = true;
      const token = sessionStorage.getItem("access_token");
      await axios
        .post(
          `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/add-other-data`,
          this.addProfilePayload,
          {
            headers: {
              ContentType: "application/json",
              Authorization: `Bearer ${token}`,
              accept: "application/json",
            },
          }
        )
        .then((responseData) => {
          console.log(`responseData: ${JSON.stringify(responseData)}`);
          if (responseData && responseData.data.status_code == 200) {
            this.loader = false;
            this.alertMsg.status = true;
            this.alertMsg.text = responseData.data.message;
            this.alertMsg.type = "success";
            this.alertMsg.color = "green";
          } else {
            this.alertMsg.status = true;
            this.alertMsg.text = `Failed: ${responseData.data.message}`;
            this.alertMsg.type = "error";
            this.alertMsg.color = "warning";
          }
          console.log("respose data", responseData);
        })
        .catch((error) => {
          console.error(error);
          this.alertMsg.status = true;
          this.alertMsg.text =
            "Error: Something went wrong please try again later!";
          this.alertMsg.type = "error";
          this.alertMsg.color = "error";
        });
      //  location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
.invalid-feedback-name {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
</style>
