<template>
  <v-container>
    <v-alert
      v-if="alertMsg.status"
      :timeout="alertMsg.timeout"
      :color="alertMsg.color"
      :type="alertMsg.type"
      dismissible
    >
      {{ alertMsg.text }}
    </v-alert>
    <v-row>
      <p class="font-weight-bold">{{ $t("bus_declaration") }}</p>
      <p class="d-flex">
        <v-checkbox
          class="mt-0"
          v-model="addProfilePayload.is_declared"
          @input="$v.is_declared.$touch()"
          :error-messages="applicantErrors"
          hide-details
        ></v-checkbox
        ><span class="mt-1">{{ $t("bus_declaration_text") }}</span>
      </p>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-btn
          color="primary"
          class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
          @click="onClickNext"
          >{{ $t("Submit") }}
        </v-btn>
      </v-col>
    </v-row>
    <loading
      :active="loader"
      :is-full-page="fullPage"
      :opacity="0.9"
      color="#ff6b00"
      :height="height"
      :width="width"
    />
  </v-container>
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import Loading from "vue-loading-overlay";
export default {
  data: () => ({
    loader: false,
    addProfilePayload: {
      is_declared: "",
    },
    alertMsg: {
      status: false,
      text: "",
      timeout: 2000,
      color: "blue-grey",
      type: "info",
    },
  }),
  computed: {
    applicantErrors() {
      const errors = [];
      if (!this.$v.addProfilePayload.is_declared.$dirty) return errors;
      !this.$v.addProfilePayload.is_declared.required &&
        errors.push("required");
      return errors;
    },
  },
  validations() {
    return {
      addProfilePayload: {
        is_declared: {
          required,
        },
      },
    };
  },
  mounted() {
    this.getdeclaration();
  },
  components: {
    Loading,
  },
  methods: {
    onClickNext() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.postList();
    },

    getdeclaration() {
      const token = sessionStorage.getItem("access_token");
      axios
        .get(
          `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/get-business-declaration`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.addProfilePayload = {
            is_declared: response.data.data[0].is_declared,
          };
        });
    },

    async postList() {
      console.log(`basicDetails: ${JSON.stringify(this.basicDetails)}`);
      this.loader = true;
      const token = sessionStorage.getItem("access_token");
      await axios
        .post(
          `${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business/add-business-declaration?is_declared=${this.addProfilePayload.is_declared}`,
          this.addProfilePayload,
          {
            headers: {
              ContentType: "application/json",
              Authorization: `Bearer ${token}`,
              accept: "application/json",
            },
          }
        )
        .then((responseData) => {
          console.log(`responseData: ${JSON.stringify(responseData)}`);
          if (responseData && responseData.data.status_code == 200) {
            this.loader = false;
            this.alertMsg.status = true;
            this.alertMsg.text = responseData.data.message;
            this.alertMsg.type = "success";
            this.alertMsg.color = "green";
          } else {
            this.alertMsg.status = true;
            this.alertMsg.text = `Failed: ${responseData.data.message}`;
            this.alertMsg.type = "error";
            this.alertMsg.color = "warning";
          }
          console.log("respose data", responseData);
        })
        .catch((error) => {
          console.error(error);
          this.alertMsg.status = true;
          this.alertMsg.text = `Failed:Something went wrong please try again sometime`;
          this.alertMsg.type = "error";
          this.alertMsg.color = "error";
        });
      //  location.reload();
    },
  },
};
</script>
