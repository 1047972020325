<template>
  <v-container>
    <v-card>
      <v-tabs
        v-model="tab"
        grow
        next-icon="mdi-arrow-right"
        prev-icon="mdi-arrow-left"
        show-arrows
        elevation="12"
      >
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab v-for="item in tabList" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card>
            <v-card-text
              ><bus-entityinfo
                @selctedEntitytype="EntityType"
                @isHideform="isHideform"
            /></v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-card-text
              ><bus-applicantinfo :selctedEntity="selctedEntity"
            /></v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="is_showAuthPage">
          <v-card>
            <v-card-text
              ><bus-authorization :selctedEntity="selctedEntity"
            /></v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text
              ><bus-documents
                :selctedEntity="selctedEntity"
                :hideForm="hideForm"
            /></v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text><bus-others /></v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text><bus-declaration /></v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
<script>
import BusEntityinfo from "./BusEntityinfo.vue";
import BusApplicantinfo from "./BusApplicantinfo.vue";
import BusDocuments from "./BusDocuments.vue";
import BusOthers from "./BusOthers.vue";
import BusDeclaration from "./BusDeclaration.vue";
import { BusUserTypes } from "../../utils/BusDocuments";
import BusAuthorization from "../BusinessUserflow/BusAuthorization/BusAuthorization.vue";
export default {
  data() {
    return {
      tab: null,
      items: [
        "Entity Information",
        "Applicant Information",
        "Authorization Information",
        "Documents",
        "Other",
        "Declaration",
      ],
      selctedEntity: "",
      tabList: [],
      is_showAuthPage: true,
      buscheckboxes: BusUserTypes(),
      hideForm: "",
    };
  },
  components: {
    BusEntityinfo,
    BusApplicantinfo,
    BusDocuments,
    BusOthers,
    BusDeclaration,
    BusAuthorization,
  },
  created() {
    this.tabList = [...this.items];
  },
  methods: {
    EntityType(value) {
      this.selctedEntity = value;
      //to get boolean value of Authorization page required or not
      for (let doc in this.buscheckboxes) {
        console.log("doc integer", doc);
        if (doc === value) {
          console.log("doc mauin", this.buscheckboxes[doc]);
          this.is_showAuthPage = this.buscheckboxes[doc].is_authrequired;
        }
      }
      //to remove tab froms tab list
      if (!this.is_showAuthPage) {
        this.tabList = this.items.filter(
          (val) => val !== "Authorization Information"
        );
      } else {
        this.tabList = this.items;
      }
    },
    isHideform(value) {
      this.hideForm = value;
    },
  },
};
</script>
